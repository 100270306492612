import React from "react";

function Select(props) {
    return (
        <label className="block">
            <span className="text-gray-700">{props.label}</span>
            <select
                className="
                    block
                    w-full
                    mt-1
                    rounded-md
                    border-gray-300
                    shadow-sm
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                "
                name={props.name}
                value={props.value}
                required={props.required ? "required" : ""}
                onChange={(e) => props.setValue(e.target.value)}
            >
                {props.options.map((option, index) => (
                    <option key={option.value + index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </label>
    );
}

function Input(props) {
    return (
        <label className="block">
            <span className="text-gray-700">{props.label}</span>
            <input
                type={props.type}
                name={props.name}
                value={props.value}
                className="
                    mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                    invalid:ring-2 invalid:ring-red-500 invalid:ring-opacity-50
                    valid:ring-2 valid:ring-green-500 valid:ring-opacity-50
                    focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
                    "
                placeholder={props.placeholder || props.label}
                onChange={(e) => props.setValue(e.target.value)}
                {...props}
            />
        </label>
    );
}

export { Input, Select };
