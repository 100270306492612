import React, { useState, useEffect, useRef } from "react";
import Layout from "./Components/Layout";
import { Select, Input } from "./Components/Form";
// import consultsSaldoWS from "./libs/consults";

const url = "https://spf.sistarbanc.com.uy/spfe/servlet/PagoEmpresa";
const urlApi = "https://rjjkquvfw2.execute-api.us-east-1.amazonaws.com/PROD/v1";
const urlApiNode = "https://sistarbanc.patmos.io:3000/v1";
const urlApiSign =
    "https://npacfvh489.execute-api.us-east-1.amazonaws.com/PROD/sign";
const urlApiPrometeo = " https://payment.prometeoapi.net/api/v1/";
const widgetIDUSD = "cdf452c3-3702-4a12-ae10-6f2348a25083";
const widgetIDUYU = "7e0d3b41-64e7-469e-8989-b3d5e1e80b85";
const APIKeyPrometeo =
    "FrNTgIRdnAgG3KkquCq0CgJGkfg0Z62gKU6xgnxNoVDNWbvAdLy1eM3WiSOYQmfv";

function App() {
    const form = useRef();
    let [ready, setReady] = useState(false);
    let [loading, setLoading] = useState(true);
    let [consult, setConsult] = useState(false);
    let [processor, setProcessor] = useState("sistarbanc");
    let [bankCode, setBankCode] = useState("");
    let [idBanco, setidBanco] = useState("");
    let [idCuenta, setIdCuenta] = useState("");
    let [importe, setImporte] = useState("");
    let [saldo, setSaldo] = useState("");
    let [producto, setProducto] = useState("");
    let [productoId, setProductoId] = useState("");
    let [productos, setProductos] = useState("");
    let [idOrganismo, setIdOrganismo] = useState("TITALMUNLI");
    let [money, setMoney] = useState("");
    let [sign, setSign] = useState("");
    let [bancos, setBancos] = useState([]);
    let ID = new Date().getTime();
    ID = ID.toString();
    let [idTransaccion, setIdTransaccion] = useState(ID);
    let [idFactura, setIdFactura] = useState(ID);
    let tipoServicio = "TJG";
    let importeGravado = "000";
    let consumidorFinal = "1";
    let urlVuelta = window.location.href;
    let tarjetas = {
        MAS: "Mastercard",
        PLU: "Lider",
        CAS: "Lider",
    };

    let [mounts, setMounts] = useState([]);

    // console.log(money, mountsByMoney, mountsByMoney[money]);
    let signAndReady = async () => {
        setReady(false);
        // idBanco, idTransaccion, idOrganismo, tipoServicio, idCuenta, idFactura, importe, moneda, importeGravado y consumidorFinal
        let values = [
            idBanco,
            idTransaccion,
            idOrganismo,
            tipoServicio,
            productoId,
            idFactura,
            importe,
            money,
            importeGravado,
            consumidorFinal,
        ];
        console.log({ values });
        console.log("cadena", values.join(""));
        let hash = await fetch(urlApiSign, {
            method: "post",
            body: JSON.stringify({
                chain: values.join(""),
            }),
        }).then((res) => res.json());
        console.log({ hash: hash.signature });
        setSign(hash.signature);
        setReady(true);
    };

    let getIntentId = async (e) => {
        setReady(false);
        let intent = await fetch(urlApiPrometeo + "payment-intent/", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                "X-API-Key": APIKeyPrometeo,
            },
            body: JSON.stringify({
                product_type: "widget",
                concept: idCuenta + productoId + "PRO",
                product_id: money === "USD" ? widgetIDUSD : widgetIDUYU,
                currency: money,
                amount: importe / 100,
                external_id: idTransaccion,
                bank_codes: [bankCode],
            }),
        })
            .then((res) => res.json())
            .catch((err) => {
                console.log("[ERROR]", err);
            });
        console.log(intent);
        setReady(true);
        return intent.intent_id;
    };

    let consultSaldo = async (e) => {
        e.preventDefault();
        if (idCuenta.length !== 8) {
            alert("Para continuar, colocá tu cédula por favor.");
            return;
        }
        setLoading(true);
        fetch(urlApiNode + "/client", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cedula: parseInt(idCuenta, 10),
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                console.log(res);
                let a = res.cuentas.CuentaItem.ListaCuentas.map((i) => ({
                    id: parseInt(i.Cuenta, 10),
                    value: i.Producto,
                    label: tarjetas[i.Producto],
                }));
                setProductos(a);
                let saldos = [];
                res.saldos.map((i) => {
                    let product = a.find(
                        (t) => t.id === parseInt(i.cuenta, 10)
                    );
                    if (product.value === "MAS") {
                        saldos.push({
                            value: (i.SaldoDolares * 100).toFixed(0),
                            label: `${product.label} - ${i.SaldoDolares} (Total Dolar)`,
                            money: "USD",
                            product: product.value,
                            cuenta: i.cuenta,
                        });
                        saldos.push({
                            value: (i.SaldoPesos * 100).toFixed(0),
                            label: `${product.label} - ${i.SaldoPesos} (Total Pesos)`,
                            money: "UYU",
                            product: product.value,
                            cuenta: i.cuenta,
                        });
                        saldos.push({
                            value: (i.PagoMinimo * 100).toFixed(0),
                            label: `${product.label} - ${i.PagoMinimo} (Minimo Pesos)`,
                            money: "UYU",
                            product: product.value,
                            cuenta: i.cuenta,
                        });
                    } else {
                        saldos.push({
                            value: (i.SaldoPesos * 100).toFixed(0),
                            label: `${product.label} - ${i.SaldoPesos} (Total Pesos)`,
                            money: "UYU",
                            product: product.value,
                            cuenta: i.cuenta,
                        });
                        saldos.push({
                            value: (i.PagoMinimo * 100).toFixed(0),
                            label: `${product.label} - ${i.PagoMinimo} (Minimo Pesos)`,
                            money: "UYU",
                            product: product.value,
                            cuenta: i.cuenta,
                        });
                    }
                    return i;
                });
                setMounts(saldos);
                setLoading(false);
                setConsult(true);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                alert(
                    "Los datos ingresados no son correctos, por favor intentá nuevamente. ¡Gracias!"
                );
            });
    };

    let handleSubmit = async (e) => {
        e.preventDefault();
        if (processor === "prometeo") {
            let intentid = await getIntentId();
            const widget = window.Prometeo.init(
                money === "USD" ? widgetIDUSD : widgetIDUYU,
                intentid
            );

            widget.on(window.Prometeo.Messaging.CLOSE, () => {
                console.log("Event: CLOSE");
                // reload the page
                window.location.reload();
            });

            widget.on(window.Prometeo.Messaging.LOGIN, (session) => {
                console.log("Event: LOGIN");
            });

            widget.on(window.Prometeo.Messaging.ERROR, (error) => {
                console.log("Event: ERROR");
                console.log(`error: ${error}`);
            });

            widget.on(window.Prometeo.Messaging.PAYMENT_SUCCESS, (payload) => {
                console.log("Event: PAYMENT_SUCCESS");
                console.log(`payload: ${payload}`);
            });

            await fetch(urlApi + "/form/prometeo", {
                method: "POST",
                body: JSON.stringify({
                    idBanco,
                    intent_id: intentid,
                    currency: money,
                    amount: importe / 100,
                    external_id: idTransaccion,
                }),
            })
                .then((res) => res.json())
                .then((res) => {
                    console.log(res);
                    widget.open({ allowedFeatureLevel: 2 });
                })
                .catch((err) => {
                    console.log(err);
                    alert(
                        "Error al enviar la transaccion, por favor intente más tarde."
                    );
                });
        } else {
            await signAndReady();
            fetch(urlApi + "/form", {
                method: "POST",
                body: JSON.stringify({
                    idBanco,
                    idTransaccion,
                    idOrganismo,
                    tipoServicio,
                    idCuenta: productoId,
                    idFactura,
                    importe,
                    moneda: money,
                    importeGravado,
                    consumidorFinal,
                    urlVuelta,
                    firma: sign,
                }),
            })
                .then((res) => res.json())
                .then((res) => {
                    console.log(res);
                    form.current.submit();
                })
                .catch((err) => {
                    console.log(err);
                    alert(
                        "Error al enviar la transaccion, por favor intente más tarde."
                    );
                });
        }
    };

    const setImporteParser = (value) => {
        console.log(value);
        let valor = value;
        if (valor.includes(",") || valor.includes(".")) {
            valor = parseFloat(valor.replace(",", ".")).toFixed(2);
            valor = parseInt(valor * 100, 10);
        } else {
            valor += "00";
            valor = parseInt(valor, 10);
        }
        setImporte(valor);
        if (producto !== "") {
            setReady(true);
        }
        console.log(importe);
    };

    useEffect(() => {
        if (importe !== "") {
            if (saldo !== "otro") {
                setProductoId(mounts.find((i) => i.value === importe).cuenta);
                setProducto(mounts.find((i) => i.value === importe).product);
                setMoney(mounts.find((i) => i.value === importe).money);
            }
            if (saldo === "otro") {
                setMoney("UYU");
            }
        }
    }, [importe, idBanco, idCuenta]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (producto !== "") {
            setProductoId(productos.find((i) => i.value === producto).id);
            setIdOrganismo(producto === "MAS" ? "TITALMUNMA" : "TITALMUNLI");
        }
    }, [producto]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log("ProductID change: ", productoId);
        setIdTransaccion(ID + idCuenta + productoId);
        setIdFactura(ID + idCuenta + productoId);
    }, [productoId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (money !== "") {
            if (processor === "sistarbanc") signAndReady();
            if (processor === "prometeo") setReady(true);
        }
    }, [idTransaccion]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (saldo !== "" && saldo !== "otro") {
            setImporte(saldo);
        }
    }, [saldo]);

    useEffect(() => {
        if (idBanco !== "") {
            setProcessor(
                bancos.find((i) => i.value === Number(idBanco)).processor
            );
            setBankCode(
                bancos.find((i) => i.value === Number(idBanco)).bank_code
            );
        }
    }, [idBanco, bancos]);

    useEffect(() => {
        if (bancos.length === 0) {
            const getBancos = async () => {
                const res = await fetch(urlApi + "/banks");
                const bancos = await res.json();
                setBancos(
                    bancos.map((i) => ({
                        value: i.id,
                        label: i.name,
                        processor: i.processor,
                        bank_code: i.bank_code,
                    }))
                );
            };
            getBancos();
            setLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout>
            <img
                src="logo.png"
                alt="Logo Italmundo"
                className="object-contain w-60 mx-auto"
            />
            <form
                ref={(ref) => (form.current = ref)}
                action={url}
                method="post"
                className="grid grid-cols-1 gap-6"
            >
                <input
                    type="hidden"
                    name="idTransaccion"
                    value={idTransaccion}
                />
                <input type="hidden" name="idOrganismo" value={idOrganismo} />
                <input type="hidden" name="tipoServicio" value={tipoServicio} />
                <input type="hidden" name="idFactura" value={idFactura} />
                <input
                    type="hidden"
                    name="importeGravado"
                    value={importeGravado}
                />
                <input
                    type="hidden"
                    name="consumidorFinal"
                    value={consumidorFinal}
                />
                <input type="hidden" name="urlVuelta" value={urlVuelta} />
                <input type="hidden" name="firma" value={sign} />
                <Select
                    label="Banco"
                    name="idBanco"
                    value={idBanco}
                    setValue={setidBanco}
                    required={true}
                    options={[
                        { value: "", label: "Seleccioná tu banco" },
                        ...bancos,
                    ]}
                />
                <Input
                    label="Cédula con dígito verificador"
                    placeholder="Ingresá el número completo"
                    type="text"
                    value={idCuenta}
                    // filter pattern to only allow numbers and max 8 digits
                    setValue={(v) =>
                        setIdCuenta(v.replace(/\D/g, "").slice(0, 8))
                    }
                    required={true}
                    min={8}
                    max={8}
                    minlenght={8}
                    maxlenght={8}
                    pattern="[0-9]{8}"
                />
                <input type="hidden" name="idCuenta" value={productoId} />
                {!consult && (
                    <>
                        <button
                            onClick={(e) => consultSaldo(e)}
                            disabled={loading}
                            className="bg-[#0DDEDE] hover:bg-[#0DDEDE] text-black py-2 px-4 rounded"
                        >
                            {loading ? "Cargando..." : "Pagar mi tarjeta"}
                        </button>
                        {loading && (
                            <div className="flex justify-center">
                                Tu información está siendo procesada, por favor
                                aguarda unos instantes. ¡Gracias!
                            </div>
                        )}
                    </>
                )}
                {consult && (
                    <>
                        <input type="hidden" name="moneda" value={money} />
                        <input type="hidden" name="importe" value={importe} />
                        <Select
                            label="Seleccioná tu saldo a abonar"
                            value={saldo}
                            setValue={setSaldo}
                            options={[
                                {
                                    value: "",
                                    label: "Seleccioná tu saldo a abonar",
                                },
                                ...mounts,
                                {
                                    value: "otro",
                                    label: "Pago Parcial en Pesos",
                                },
                            ]}
                        />
                        {saldo === "otro" && (
                            <>
                                <Select
                                    label="Seleccioná tu tarjeta"
                                    value={producto}
                                    setValue={setProducto}
                                    options={[
                                        {
                                            value: "",
                                            label: "Seleccioná tu tarjeta",
                                        },
                                        ...productos,
                                    ]}
                                />
                                <Input
                                    label="Completá el importe a pagar"
                                    type="text"
                                    placeholder="Ingresá el importe seguido de “,” ó “.”"
                                    setValue={setImporteParser}
                                />
                            </>
                        )}
                        <button
                            onClick={(e) => handleSubmit(e)}
                            disabled={Number(importe) <= 0 || !ready}
                            type="submit"
                            className="bg-[#0DDEDE] hover:bg-[#0DDEDE] disabled:bg-gray-300 text-black py-2 px-4 rounded"
                        >
                            Ir a Pagar
                        </button>
                    </>
                )}
            </form>
        </Layout>
    );
}
export default App;
