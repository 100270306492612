import React from "react";

function Layout(props) {
    return (
        <div className="relative flex min-h-screen flex-col justify-center overflow-hidden py-6 sm:py-12 bg-gray-50 bg-form bg-cover bg-center bg-no-repeat">
            <div className="relative bg-white px-6 pt-10 pb-12 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10">
                <div className="mx-auto max-w-sm">
                    <div className="divide-y divide-gray-300/50">
                        <div className="space-y-6 pb-4 text-base leading-7 text-gray-600">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
            <span className="absolute bottom-2 right-2 text-xs text-gray-400">
                v1.1.1
            </span>
        </div>
    );
}

export default Layout;
